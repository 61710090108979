import { createStore, applyMiddleware, compose } from "redux";
import receivableReducer from "../reducers/receivable";
import createSagaMiddleware from "redux-saga";
import receivableApi from "../sagas/receivable-api";

const receivableSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const receivableStore = createStore(
	receivableReducer,
	storeEnhancers(applyMiddleware(receivableSagaMiddleware))
);

receivableSagaMiddleware.run(receivableApi);

export default receivableStore;
