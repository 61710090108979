import { CUSTOMER_LIST_LOADED, API_ERRORED } from "../constants/action-types";

const initialState = {
    list: []
};

function customerReducer(state = initialState, action) {
    if(action.type === CUSTOMER_LIST_LOADED) {
        return Object.assign({}, state, {
            list: action.payload
        });
    } else if(action.type === API_ERRORED) {
        return Object.assign({}, state, {
            //TODO: show error message, do not add it to the list
            list: [{id: 1, title: 'API ERROR'}]
        });
    } else {
        return state;
    }
};

export default customerReducer;
