import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import icons from './icons';

const styles = theme => ({
	root: {
        fontFamily: 'Roboto',
        fontSize: theme.typography.fontSize,
        lineHeight: '18px',
        color: '#212121',
        display: 'float'
	},
    icon: {
        color: theme.palette.primary.main,
        float: 'right',
        marginRight: 5,
        height: 21,
        width: 21
    },
});

class StatusBar extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {icons.Print({className: classes.icon})}
            </div>
        );
    }
}

StatusBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatusBar);
