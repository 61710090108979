import React, { Component } from 'react';
import { connect } from "react-redux";

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import store from "./store/index";
import { loginRequested, logoutPerformed } from "./actions/authentication";

import Sidebar   from './components/generic/Sidebar';
import Header    from './components/generic/Header';
import Footer    from './components/generic/Footer';
import StatusBar from './components/generic/StatusBar';

import Dashboard  from './components/Dashboard';
import Customer  from './components/Customer';
import UnderConstruction from './components/UnderConstruction';

import logo from './123banklogo.png';
import chatbutton from './chatbutton.svg';
import waitBackground from './background.jpg';

import './App.scss';
import './App.css';

import awsConfig from './config.json';

import {CognitoAuth} from 'amazon-cognito-auth-js';

import { checkAuth } from './middleware/authentication';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%'
    },
    grid : {
        position: 'fixed',
        top: '75px',
        height: '100%',
        width: '100%',
        display: 'flex',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    workarea: {
        padding: '25px 25px 25px 25px',
        width: '100%'
    }
});

const mapStateToProps = state => {
    return { 
        auth: state.auth,
    };
};

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showChatWindow : false,
        };
        this.toggleChatWindowVisibility = this.toggleChatWindowVisibility.bind(this);
    }

    toggleChatWindowVisibility() {
        const currentState = this.state.showChatWindow;
        this.setState({ showChatWindow: !currentState });
    };

    renderLogin() {
        var auth = new CognitoAuth(awsConfig.Auth);
        auth.useCodeGrantFlow();

        const loginUrl = 'https://' + awsConfig.Auth.AppWebDomain + 
                         '/oauth2/authorize?response_type=code&client_id=' + awsConfig.Auth.ClientId + 
                         '&redirect_uri=' + awsConfig.Auth.RedirectUriSignIn;

        console.log("Hello");
        console.log(awsConfig);
        return (
            <div>
                <section className="hero is-primary">
	                <div className="hero-body">
                        <div>
                            <figure className="image container is-256x256">
                                <img src={logo} alt=""></img>
                            </figure>
                            <div className="container has-text-centered">
                                <h1 className="title">Backoffice</h1>
                                <p className="subtitle">Open Credit Dev</p>
                                <a className="button" href={loginUrl}>Login</a>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="footer">
                    <div className="content has-text-centered">
                        <p>
                            <strong>OpenCredit</strong> by <a href="https://collenda.com">Collenda</a> - <a href="https://api-doc.123-bank.com/">API</a>
                        </p>
                    </div>
                </footer>
            </div>
        );
    }

    renderLoggedInPage() {
        const chatURL = 'https://chat.123-bank.com/chat/3/';
        const { classes } = this.props;

        return (
            <div>
                <Router>
                    <MuiThemeProvider theme={theme}>
                        <div className={classes.root}>
                            <Header/>
                            <div className={classes.grid}>
                                <Sidebar model={[[
                                    {label: 'Dashboard', icon: 'DashboardIcon', path: '/dashboard'}
                                ], [
                                    {label: 'Quick overview', icon: 'OverviewIcon'   , path: '/under_construction'},
                                    {label: 'Customer'      , icon: 'PartnerIcon'    , path: '/customer'},
                                    {label: 'Receivable'    , icon: 'ReceivablesIcon', path: '/under_construction'}
                                ], [
                                    {label: 'Import', icon: 'ImportIcon', path: '/under_construction'}
                                ], [
                                    {label: 'Prepare court application', icon: 'CourtIcon', children: [
                                        {label: 'MB - batch job', icon: 'NewWindowIcon', path: '/under_construction'},
                                        {label: 'VB - batch job', icon: 'Redo'         , path: '/under_construction'}
                                    ]},
                                    {label: 'Submit court application' , icon: 'CourtIcon', children: [
                                        {label: 'Initial application', icon: 'NewWindowIcon', path: '/under_construction'},
                                        {label: 'Retry application'  , icon: 'Redo'         , path: '/under_construction'}
                                    ]},
                                    {label: 'Record news', icon: 'RecordIcon', path: '/under_construction'}
                                ], [
                                    {label: 'Administration', icon: 'SettingsIcon', children: [
                                        {label: 'Clerk'          , icon: 'People'   , path: '/under_construction'},
                                        {label: 'Function lock'  , icon: 'Block'    , path: '/under_construction'},
                                        {label: 'Letter category', icon: 'Apps'     , path: '/under_construction'},
                                        {label: 'Letters'        , icon: 'Mail'     , path: '/under_construction'},
                                        {label: 'Applicant data' , icon: 'CourtIcon', path: '/under_construction'},
                                    ]}
                                ], [
                                    {label: 'Help'        , icon: 'HelpIcon', path: '/under_construction'},
                                    {label: 'Informations', icon: 'InfoIcon', path: '/under_construction'}
                                ]]}/>
                                <div className={classes.workarea}>
                                    <StatusBar/>
                                    <Switch>
                                        <Route path='/dashboard'          component={Dashboard} />
                                        <Route path='/customer'           component={Customer} />
                                        <Route path='/under_construction' component={UnderConstruction} />
                                        <Route path='/callback'           component={Dashboard} />
                                        <Route path='/'                   component={Dashboard} />
                                    </Switch>
                                    <Footer/>
                                </div>
                            </div>
                        </div>
                    </MuiThemeProvider>
                </Router>
                <div id="chatbutton" onClick={this.toggleChatWindowVisibility}>
                    <img src={chatbutton} alt="ChatButton"/>
                </div>
                <div id="chatwindow" className={this.state.showChatWindow ? 'show' : 'hidden'}>
                    <iframe title="chatWindow_1" src={chatURL} className="chatwindow_iframe"></iframe>
                </div>
            </div>
        );  
    }

    renderWait() {
        return (
            <div>
                <img src={waitBackground} alt="Background" className="loggedInBackgroundImage"/>
            </div>
        );
    }

    render() {
        if(checkAuth()) {
            if(window.location.href.indexOf("signout") > 0) {
                store.dispatch(logoutPerformed());
                return this.renderWait();
            } else {
                return this.renderLoggedInPage();
            }
        } else {
            if(window.location.href.indexOf("callback") > 0) {
                const urlParams = new URLSearchParams(window.location.search);
                store.dispatch(loginRequested(urlParams.get('code')));
                return this.renderWait();
            } else {
                return this.renderLogin();
            }
        }
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(App));
//export default withStyles(styles)(App);
