import { createStore, applyMiddleware, compose } from "redux";
import customerReducer from "../reducers/customer";
import createSagaMiddleware from "redux-saga";
import customerApi from "../sagas/customer-api";

const customerSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const customerStore = createStore(
	customerReducer,
	storeEnhancers(applyMiddleware(customerSagaMiddleware))
);

customerSagaMiddleware.run(customerApi);

export default customerStore;
