import React, { Component } from "react";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import icons from './icons';

import { DATE } from "../../constants/data-types";

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends Component {

    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };

    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={classes.root}>
                <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={this.handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={this.handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }    
}

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);

const CustomTableCell = withStyles(theme => ({
    head: { 
        color: '#000000',
        border: '1px solid ' + theme.palette.primary.text,
        textAlign: 'center',
        fontSize: '14px',
        whiteSpace: 'nowrap'
    },
    body: { 
        fontSize: '14px',
        border: '1px solid ' + theme.palette.primary.text,
    },
    root: {
        padding: '1px 15px 1px 15px',
        '&:last-child': {
            borderRight: '0px',
        },
        '&:first-child': {
            borderLeft: '0px',
        }        
    }
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tableTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '21px',
        padding: '9px 14px 9px 14px'
    },
    tableHeaderRow: {
        height: '38px',
    },
    tableHeaderCellContent: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class SimpleTable extends Component {
    state = {
        page: 0,
        rowsPerPage: 9,
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    componentDidMount() {
        // calling the new action creator
        // TODO: check if getData is a function
        if(this.props.getData) {
        	this.props.getData();
    	}
    }

    render() {
        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;
        const noRows = this.props.data ? this.props.data.length : 0;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, noRows - page * rowsPerPage);

        return (
        	<Paper className={classes.root}>
                <div className={classes.tableTitle}>{
                    '' + (this.props.data ? this.props.data.length : 0) + ' ' + this.props.model.title
                }</div>
				<Table className={classes.table}>
	        		<TableHead>
	          			<TableRow className={classes.tableHeaderRow}>
	        			    {this.props.model.attributes.map((attr, idx) => (
	            				<CustomTableCell key={idx}>
                                    <span>{attr.label}</span>
                                    {icons.Sort({className: classes.tableHeaderCellContent})}
                                </CustomTableCell>
	        			    ))}
	          			</TableRow>
	        		</TableHead>
	        		<TableBody>
	          			{this.props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, r_idx) => (
	            			<TableRow key={r_idx} className={classes.row}>
	            			    {this.props.model.attributes.map((attr, c_idx) => {
                                    var value = attr.type === DATE ? 
                                        formatDate(row[attr.name]) : 
                                        attr.name.split(' ').reduce((v, p, i) => {
                                            var ev = p.split('.').reduce((r, a) => r[a], row);
                                            ev = ev != null || ev != undefined ? (i > 0 ? ' ' : '') + ev : '';
                                            return p.length > 0 ? v + ev : v;
                                        }, '');

                                    return (
	              					    <CustomTableCell key={'' + r_idx + '_' + c_idx}>
                                            {value}
                                        </CustomTableCell>
	            			        );
                                })}
	            			</TableRow>
	          			))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 30 * emptyRows }}>
                                <TableCell colSpan={this.props.model.attributes.length} />
                            </TableRow>
                        )}
	        		</TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={noRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{ native: true }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped} />
                        </TableRow>
                    </TableFooter>                    
	      		</Table>
	      	</Paper>
        );
    }
}

function formatDate(jsonDate) {
    var monthNames = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct","Nov", "Dec"];

    var date = new Date(jsonDate);
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + '.' + monthNames[monthIndex] + '.' + year;
}

SimpleTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);
