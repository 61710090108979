import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import icons from './icons';

const styles = theme => ({
    hide: {
        display: 'none'
    },
    root: {
        width           : '360px',
        height          : '100%',
        backgroundColor : theme.palette.primary.main + ' !important',
        color           : theme.palette.primary.contrastText,
        fontSize        : '14px',
        fontFamily      : 'Roboto',
        boxShadow       : '3px 0 6px rgba(0, 0, 0, 0.3)',
        transition      : 'margin-left 0.3s'
    },
    item: { 
        height: '32px'
    },
    selected: { 
        backgroundColor : theme.palette.secondary.main + ' !important',
        color           : theme.palette.secondary.contrastText
    },
    divider: { 
        backgroundColor : '#808080'
    },
    icon: { 
        color : theme.palette.primary.contrastText + ' !important',
        margin: '5px',
    },
    iconSelected: { 
        color : theme.palette.secondary.contrastText + ' !important',
        margin: '5px',
    },
    childItem: {
        paddingLeft: '20px'
    }
});

const mapStateToProps = state => {
  return { open: state.sidebar.open };
};

class Sidebar extends Component {
    state = {
    	selectedGroup : 0,
        selectedIndex : 0,
        selectedChild : null,
        expandedItem  : null,
    };

    handleListItemClick = (event, label, group, index, child, path) => {
        this.setState({ 
            selectedGroup: group, 
            selectedIndex: index,
            selectedChild: child});
        if(!path) {
            this.setState({
                selectedChild: null,
                expandedItem: this.state.expandedItem === label ? null : label});
        }
        this.props.history.push(path);
    };

    render() {
        const { classes }       = this.props;
        const { open }          = this.props;
        const { selectedGroup } = this.state;
        const { selectedIndex } = this.state;
        const { selectedChild } = this.state;
        const { expandedItem }  = this.state;

        return (
            <div className={classNames(classes.root, !open && classes.hide)}>
                {this.props.model.map((grp, grpIdx) => (
                	<div key={grpIdx}>
		                <List component="nav">
		                    {grp.map((item, idx) => (
                                <div key={'div' + grpIdx + '_' + idx}>
    			                    <ListItem key={'' + grpIdx + '_' + idx} button 
    			                        selected={selectedGroup === grpIdx && selectedIndex === idx} 
    			                        onClick={event => this.handleListItemClick(event, item.label, grpIdx, idx, 0, item.path)}
                                        classes={{root: classes.item, selected: classes.selected}}>
                                        <ListItemIcon>
                                            {icons[item.icon]({
                                                classes: {
                                                    root: ((selectedGroup === grpIdx && selectedIndex === idx) ? classes.iconSelected : classes.icon)
                                                }
                                            })}
                                        </ListItemIcon>
    			                        <ListItemText primary={item.label} disableTypography/>
                                        {item.children && (expandedItem === item.label ? <ExpandLess /> : <ExpandMore />)}
    			                    </ListItem>
                                    {item.children && (
                                        <Collapse in={expandedItem === item.label} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {item.children.map((childItem, childIdx) => (
                                                    <ListItem key={'' + grpIdx + '_' + idx + '_' + childIdx} button 
                                                        selected={selectedGroup === grpIdx && selectedIndex === idx && selectedChild === childIdx} 
                                                        onClick={event => this.handleListItemClick(event, childItem.label, grpIdx, idx, childIdx, childItem.path)}
                                                        classes={{root: classes.item, selected: classes.selected}}>
                                                        <ListItemIcon className={classes.childItem}>
                                                            {icons[childItem.icon]({
                                                                classes: {
                                                                    root: selectedGroup === grpIdx && selectedIndex === idx && selectedChild === childIdx ? classes.iconSelected : classes.icon
                                                                }
                                                            })}
                                                        </ListItemIcon>
                                                        <ListItemText primary={childItem.label} disableTypography/>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                </div>
		                    ))}
		                </List>
		                <Divider classes={{root: classes.divider}}/>
		            </div>
	            ))}
            </div>
        );
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Sidebar)));
