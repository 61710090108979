import { DUNNING_LIST_REQUESTED, DUNNING_LIST_LOADED, API_ERRORED } from "../constants/action-types";

export function getDunningList() {
    return { type: DUNNING_LIST_REQUESTED }
};

export function dunningListLoaded(payload) {
    return { type: DUNNING_LIST_LOADED, payload }
};

export function apiError(payload) {
	//TODO: check the payload for detail message ... maybe log it
    return { type: API_ERRORED, payload : {id: 1, message: 'API error!'} }
};
