import React, { Component } from 'react';
import { connect } from "react-redux";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { sidebarClicked } from "../../actions/sidebar";

import icons from './icons';

import config from '../../config.json';

const styles = theme => ({
    appBar: {
        display: 'flex',
        flexDirection: 'initial',
        flexGrow: 1,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    brandCorner: {
        backgroundColor: '#000000',
        height: '75px',
        width: '351px',
        padding: '20px 10px 10px 20px',
        display: 'flex',
    },
    brandImage: {
        backgroundImage: 'url(logo-collenda-bg-dark-42.png)',
        width: '42px',
        height: '42px',
    },
    brandText: {
        color: theme.palette.secondary.text,
        fontSize: 24,
        padding: '1px 0px 0px 24px'
    },
    header: {
        display: 'flex',
        height: '75px',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    toolbar: { 
        padding: '10px 10px 10px 10px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%'
    },
    textField: { 
        marginTop: '0px',
        marginBottom: '0px',
        paddingRight: '20px'
    },
    textFieldInput: { 
        borderBottom: "1px solid " + theme.palette.primary.text
    },
    textFieldInputFocused : { 
        borderBottom: "1px solid " + theme.palette.primary.contrastText 
    },
    textFieldInputError : {

    },
    textFieldLabel : { 
        color: theme.palette.primary.text,
        fontSize: theme.typography.fontSize
    },
    textFieldLabelFocused : {
        color: theme.palette.primary.contrastText + '!important'
    },
    textFieldLabelError : {

    },
    icon: { 
        margin: '15px 5px 15px 5px'
    },
    menuButton: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: '38px',
        width: '38px',
        left: '-20px',
        top: '16px',
        padding: '0px 0px 0px 0px',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            transform: 'scale(1.2)'
        },
        transition: 'all 0.3s',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16)'
    },
    menuButtonIcon: {
    },
    anchorButton: {
        padding: '0px 0px 0px 0px',
        color: theme.palette.primary.contrastText,
        height: '54px',
        '&:hover': {
            color: '#FFFFFF'
        }
    },
});

const SearchField = withStyles(styles)((props) => {
    const { classes } = props;
    return (
        <TextField  id={props.id}
                    label={props.label}
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.textFieldLabel, 
                            focused: classes.textFieldLabelFocused,
                            error: classes.textFieldLabelError}}}
                    InputProps={{
                        classes: {
                            root: classes.textFieldInput,
                            focused: classes.textFieldInputFocused,
                            error: classes.textFieldInputError}}}
                    margin="normal"/>
    );
});

const mapStateToProps = state => {
  return { 
    open: state.sidebar.open, 
    auth: state.auth };
};

function mapDispatchToProps(dispatch) {
  return {
    sidebarClicked  : open => dispatch(sidebarClicked(open))
  };
}

class Header extends Component {

    handleDrawerClick = () => {
        this.props.sidebarClicked(!this.props.open);
    };

    render() {
        const { classes } = this.props;
        const { open } = this.props;

        const logoutUrl = 
            'https://'         + config.Auth.AppWebDomain       + '/logout' + 
                '?client_id='  + config.Auth.ClientId           + 
                '&logout_uri=' + config.Auth.RedirectUriSignOut;

        return (
            <AppBar position="fixed" className={classes.appBar}>
                    <div className={classes.brandCorner}>
                        <div className={classes.brandImage}>&nbsp;</div>
                        <Typography component="p" className={classes.brandText}>123 Bank</Typography>
                    </div>
                    <div className={classes.header}>
                        <IconButton color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerClick}
                                    className={classes.menuButton}>
                            {icons[open?'ArrowLeft':'ArrowRight']({className: classes.menuButtonIcon})}
                        </IconButton>
                        <div className={classes.toolbar}>
                            <SearchField id="Partnernummer" label="Partnernummer"/>
                            <SearchField id="intForderungsnummer" label="int. Forderungsnummer"/>
                            <SearchField id="extForderungsnummer" label="ext. Forderungsnummer"/>
                            {icons.SearchIcon(   {className: classes.icon})}
                            {icons.AddIcon(      {className: classes.icon})}
                            {icons.NewWindowIcon({className: classes.icon})}
                            {icons.SettingsIcon( {className: classes.icon})}
                            <a className={classes.anchorButton} href={logoutUrl}>
                                {icons.LogoutIcon(   {className: classes.icon})}
                            </a>
                        </div>
                    </div>
            </AppBar>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
