import React from "react";
import { connect, Provider } from "react-redux";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import store from "../store/customer";
import { getCustomerList } from "../actions/customer";
import { STRING } from "../constants/data-types";
import Table from "./generic/Table";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const getData = getCustomerList;

const CustomerTable = connect((state) => {
    return { data: state.list };
}, { getData })(Table);

const tableModel = {
    title: 'Customers',
    attributes : [
        {name: 'name1 name2', label: 'Name' , type: STRING},
        {name: 'mainAddress.street mainAddress.number mainAddress.zip mainAddress.city mainAddress.country', label: 'Address', type: STRING}]
}

function CustomerApp(props) {
    const { classes } = props;
    return (
        <Provider store={store}>
            <div className={classes.root}>
                <h2>Customers</h2>
                <CustomerTable model={tableModel}/>
            </div>
        </Provider>
    );
};

CustomerApp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerApp);
