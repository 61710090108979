import { LOGIN_PERFORMED, LOGOUT_PERFORMED } from "../constants/action-types";

function authenticationReducer(state = null, action) {
    if(action.type === LOGIN_PERFORMED) {
        return Object.assign({}, state, action.payload);
    } else if(action.type === LOGOUT_PERFORMED) {
        return Object.assign({}, state, null);
    } else {
        return state;
    }
};

export default authenticationReducer;
