import { takeEvery, call, put } from "redux-saga/effects";
import { DUNNING_LIST_REQUESTED } from "../constants/action-types";
import { dunningListLoaded, apiError } from "../actions/dunning";
import config from '../config.json';
import axios from 'axios'
import { buildAuthorizationHeader } from '../middleware/authentication';

const httpsClient = axios.create({
    baseURL: config.API.URL,
    timeout: 10000,
});

export default function* watcherSaga() {
    yield takeEvery(DUNNING_LIST_REQUESTED, workerSaga);
}

function* workerSaga() {
    try {
        const payload = yield call(getData);
        yield put(dunningListLoaded(payload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function getData() {
	return httpsClient.get('/legalDunnings', { 
        'headers' : { 
            'Authorization'               : buildAuthorizationHeader(),
            'Content-Type'                : 'application/json',
        } 
    }).then((response) => {
        return response.data
    }).catch((error) => {
        console.log(error);
    });
}
