import { createStore, applyMiddleware, compose } from "redux";
import dunningReducer from "../reducers/dunning";
import createSagaMiddleware from "redux-saga";
import dunningApi from "../sagas/dunning-api";

const dunningSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const dunningStore = createStore(
	dunningReducer,
	storeEnhancers(applyMiddleware(dunningSagaMiddleware))
);

dunningSagaMiddleware.run(dunningApi);

export default dunningStore;
