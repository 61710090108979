import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import icons from './icons';

const styles = theme => ({
    root: {
        minWidth: 275,
    },
    header: {
        padding: 0
    },
    title: {
        fontSize: 20,
        paddingLeft: 25,
        textAlign: 'center',
        lineHeight: '20px',
        marginTop: '14px'
    },
    value: {
        fontSize: 36,
        paddingLeft: 25,
        textAlign: 'center',
        color: '#000000',
        lineHeight: '36px',
        marginBottom: '14px'
    },
    icon: {
        margin: 7,
        width: '48px',
        height: '48px',
    },
});

class SimpleCard extends Component {

    componentDidMount() {
        // calling the new action creator
        // TODO: check if getData is a function
        if(this.props.getData) {
            this.props.getData();
        }
    }

    render() {
        const { classes } = this.props;
        const { icon }    = this.props;
        const { title }   = this.props;
        const { color }   = this.props;

        return (
            <Card className={classes.root}>
                <CardHeader 
                    classes={{
                        root      : classes.header,
                        title     : classes.title,
                        subheader : classes.value}}
                    avatar={icons[icon]({className: classes.icon, style: {color: color}})}
                    title={title}
                    subheader={this.props.data}/>
            </Card>        
        );
    }
}

SimpleCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCard);
