import React from 'react';
import PropTypes from 'prop-types';

import { connect, Provider } from "react-redux";

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import receivableStore from "../store/receivable";
import dunningStore    from "../store/dunning";
import taskStore       from "../store/task";

import { getReceivableList } from "../actions/receivable";
import { getDunningList }    from "../actions/dunning";
import { getTaskList }       from "../actions/task";

import { STRING, DATE } from "../constants/data-types";

import Table from "./generic/Table";
import SimpleCard from "./generic/SimpleCard";

import theme from '../theme'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
});

const TaskTable = connect((state) => {
    return { data: state.list };
}, { getData : getTaskList })(Table);

const ReceivableSolvedCard = connect((state) => {
    return { data: (state.list ? state.list.filter(r => r.completionDate).length : 0) };
}, { getData : getReceivableList })(SimpleCard);

const ReceivableOpenCard = connect((state) => {
    return { data: (state.list ? state.list.filter(r => !r.completionDate).length : 0) };
}, { getData : getReceivableList })(SimpleCard);

const DunningCard = connect((state) => {
    return { data: (state.list ? state.list.length : 0) };
}, { getData : getDunningList })(SimpleCard);

const TaskCard = connect((state) => {
    return { data: (state.list ? state.list.length : 0) };
}, { getData : getTaskList })(SimpleCard);

function Dashboard(props) {
    const { classes } = props;

    return (
        <Grid container className={classes.root} spacing={16}>
            <Grid item xs={12}>
                <Grid container className={classes.demo} justify="center" spacing={16}>
                    <Grid key='receivable_solved' item xs={3}>
                        <Provider store={receivableStore}>
                            <ReceivableSolvedCard 
                                icon="Check" 
                                color={theme.palette.secondary.main} 
                                title="Receivables Solved"/>
                        </Provider>
                    </Grid>
                    <Grid key='receivable_open' item xs={3}>
                        <Provider store={receivableStore}>
                            <ReceivableOpenCard 
                                icon="Shop" 
                                color={theme.palette.primary.light} 
                                title="Receivables Open"/>
                        </Provider>
                    </Grid>
                    <Grid key='dunnings' item xs={3}>
                        <Provider store={dunningStore}>
                            <DunningCard 
                                icon="CourtIcon" 
                                color={theme.palette.primary.main} 
                                title="Legal Dunnings"/>
                        </Provider>
                    </Grid>
                    <Grid key='tasks' item xs={3}>
                        <Provider store={taskStore}>
                            <TaskCard 
                                icon="Event" 
                                color={theme.palette.primary.text} 
                                title="Tasks"/>
                        </Provider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Provider store={taskStore}>
                    <TaskTable model={{
                        title: 'Tasks',
                        attributes : [
                            {name: 'text'         , label: 'Text'         , type: STRING},
                            {name: 'date'         , label: 'Date'         , type: DATE  },
                            {name: 'id'           , label: 'Id'           , type: STRING},
                            {name: 'clerk'        , label: 'Clerk'        , type: STRING},
                            {name: 'registerNo'   , label: 'Register No.' , type: STRING},
                            {name: 'prio'         , label: 'Prio'         , type: STRING}]
                        }}/>
                </Provider>
            </Grid>
        </Grid>
    );
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
