import { createStore, applyMiddleware, compose } from "redux";
import taskReducer from "../reducers/task";
import createSagaMiddleware from "redux-saga";
import taskApi from "../sagas/task-api";

const taskSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const taskStore = createStore(
	taskReducer,
	storeEnhancers(applyMiddleware(taskSagaMiddleware))
);

taskSagaMiddleware.run(taskApi);

export default taskStore;
