import { createStore, applyMiddleware, compose } from 'redux'
import reducer from '../reducers/index'
import { authenticationMiddleware } from "../middleware/authentication";
import { AUTH_KEY } from "../constants/local-storage-keys";
import createSagaMiddleware from "redux-saga";
import authenticationSaga from "../sagas/authentication";

const initialState = {
	auth : localStorage.getItem(AUTH_KEY)
}

const initialiseSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	reducer,
	initialState,
	storeEnhancers(
	    applyMiddleware(authenticationMiddleware, initialiseSagaMiddleware)
	)
);

initialiseSagaMiddleware.run(authenticationSaga);

export default store;
