import { TASK_LIST_REQUESTED, TASK_LIST_LOADED, API_ERRORED } from "../constants/action-types";

export function getTaskList() {
    return { type: TASK_LIST_REQUESTED }
};

export function taskListLoaded(payload) {
    return { type: TASK_LIST_LOADED, payload }
};

export function apiError(payload) {
	//TODO: check the payload for detail message ... maybe log it
    return { type: API_ERRORED, payload : {id: 1, message: 'API error!'} }
};
