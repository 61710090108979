import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
        fontFamily: 'Roboto',
        fontSize: theme.typography.fontSize,
        lineHeight: '18px',
        color: '#212121',
        marginTop: '20px'
	}
});

class Footer extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <span>Collenda Open Credit 0.1.0 - 17.04.2019</span>
                <span style={{float: 'right'}}>All Rights Reserved</span>
            </div>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
