import { LOGIN_PERFORMED, LOGOUT_PERFORMED } from "../constants/action-types";
import { AUTH_KEY } from "../constants/local-storage-keys";

export function authenticationMiddleware({dispatch}) {
    return function(next) {
        return function(action) {
            if(action.type === LOGIN_PERFORMED) {
                localStorage.setItem(AUTH_KEY, JSON.stringify(action.payload));
            } else if(action.type === LOGOUT_PERFORMED) {
                localStorage.removeItem(AUTH_KEY)
            }
            return next(action);
        };
    };
}

export function buildAuthorizationHeader() {
    const auth = JSON.parse(localStorage.getItem(AUTH_KEY));
    return auth ? auth.token_type + ' ' + auth.id_token : '';
}

export const getJWTObject = (): object => {
    function parseJwt (token) {
        if (!token) return;
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }
    return parseJwt(localStorage.getItem(AUTH_KEY))
};

export const checkAuth = (): boolean => {
    const jwt = getJWTObject();
    if (!jwt) return false;
    if (Date.now() / 1000 > jwt.exp) {
        localStorage.removeItem(AUTH_KEY);
        return false;
    }
    return true
};
