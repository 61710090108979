export const API_ERRORED    = "API_ERRORED";

export const SIDEBAR_CLICKED = "SIDEBAR_CLICKED";

export const LOGIN_REQUESTED  = "LOGIN_REQUESTED";
export const LOGIN_PERFORMED  = "LOGIN_PERFORMED";
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_PERFORMED = "LOGOUT_PERFORMED";

export const CUSTOMER_LIST_REQUESTED = "CUSTOMER_LIST_REQUESTED";
export const CUSTOMER_LIST_LOADED    = "CUSTOMER_LIST_LOADED";

export const TASK_LIST_REQUESTED = "TASK_LIST_REQUESTED";
export const TASK_LIST_LOADED    = "TASK_LIST_LOADED";

export const DUNNING_LIST_REQUESTED = "DUNNING_LIST_REQUESTED";
export const DUNNING_LIST_LOADED    = "DUNNING_LIST_LOADED";

export const RECEIVABLE_LIST_REQUESTED = "RECEIVABLE_LIST_REQUESTED";
export const RECEIVABLE_LIST_LOADED    = "RECEIVABLE_LIST_LOADED";
