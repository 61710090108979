import { LOGIN_REQUESTED, LOGIN_PERFORMED, LOGOUT_REQUESTED, LOGOUT_PERFORMED, API_ERRORED } from "../constants/action-types";

export function loginRequested(payload) {
    return { type: LOGIN_REQUESTED, payload }
};

export function loginPerformed(payload) {
    return { type: LOGIN_PERFORMED, payload }
};

export function logoutRequested(payload) {
    return { type: LOGOUT_REQUESTED, payload }
};

export function logoutPerformed(payload) {
    return { type: LOGOUT_PERFORMED, payload }
};

export function apiError(payload) {
	//TODO: check the payload for detail message ... maybe log it
    return { type: API_ERRORED, payload : {id: 1, message: 'Login error!'} }
};
