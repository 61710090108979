import { takeEvery, call, put } from "redux-saga/effects";
import { CUSTOMER_LIST_REQUESTED } from "../constants/action-types";
import { customerListLoaded, apiError } from "../actions/customer";
import config from '../config.json';
import axios from 'axios'
import { buildAuthorizationHeader } from '../middleware/authentication';

const httpsClient = axios.create({
    baseURL: config.API.URL,
    timeout: 300000,
});

export default function* watcherSaga() {
    yield takeEvery(CUSTOMER_LIST_REQUESTED, workerSaga);
}

function* workerSaga() {
    try {
        const payload = yield call(getData);
        yield put(customerListLoaded(payload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function getData() {
    /*
	return httpsClient.get('/customers', { 
        'headers' : { 
            'Authorization'               : buildAuthorizationHeader(),
            'Content-Type'                : 'application/json',
        } 
    }).then(response => response.data);
    */
    return httpsClient.post('/graphql', '{\n' + 
        '    customers(size: 15) {\n' +
        '    id\n' + 
        '    name1\n' + 
        '    name2\n' +
        '    mainAddress {\n' +
        '        street\n' +
        '        number\n' +
        '        zip\n' +
        '        city\n' +
        '        country\n' +
        '    }\n' +
    '}}', { 
        'headers' : { 
            'Authorization'               : buildAuthorizationHeader(),
            'Content-Type'                : 'application/json',
        } 
    }).then(response => response.data ? response.data.data.customers : undefined);
}
