import { takeEvery, call, put } from "redux-saga/effects";

import { LOGIN_REQUESTED, LOGOUT_REQUESTED } from "../constants/action-types";

import { loginPerformed, logoutPerformed, apiError } from "../actions/authentication";

import config from '../config.json';

import axios from 'axios'
import qs from 'qs'

const httpsClient = axios.create({
    baseURL: config.API.URL,
    timeout: 10000,
});

export default function* watcherSaga() {
    yield takeEvery(LOGIN_REQUESTED, loginWorkerSaga);
    yield takeEvery(LOGOUT_REQUESTED, logoutWorkerSaga);
}

function* loginWorkerSaga(action) {
    try {
        const payload = yield call(getToken, action.payload);
        yield put(loginPerformed(payload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function* logoutWorkerSaga(action) {
    try {
        const payload = yield call(logout, action.payload);
        yield put(logoutPerformed(payload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function getToken(code) {
    return httpsClient.post('https://' + config.Auth.AppWebDomain + '/oauth2/token', qs.stringify({
        'grant_type'   : 'authorization_code',
        'client_id'    : config.Auth.ClientId,
        'code'         : code,
        'redirect_uri' : config.Auth.RedirectUriSignIn
    }), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(response => response.data);
}

function logout() {
    return httpsClient.get(
        'https://'         + config.Auth.AppWebDomain       + '/logout' + 
            '&client_id='  + config.Auth.ClientId           + 
            '&logout_uri=' + config.Auth.RedirectUriSignOut, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    }).then((response) => {
        return response.data
    }).catch((error) => {
        console.log(error);
    });
}
