import { SIDEBAR_CLICKED } from "../constants/action-types";

const initialState = {
    open: true
};

function sidebarReducer(state = initialState, action) {
    if(action.type === SIDEBAR_CLICKED) {
        return Object.assign({}, state, {
            open: action.payload
        });
    } else {
        return state;
    }
};

export default sidebarReducer;
