import { CUSTOMER_LIST_REQUESTED, CUSTOMER_LIST_LOADED, API_ERRORED } from "../constants/action-types";

export function getCustomerList() {
    return { type: CUSTOMER_LIST_REQUESTED }
};

export function customerListLoaded(payload) {
    return { type: CUSTOMER_LIST_LOADED, payload }
};

export function apiError(payload) {
	//TODO: check the payload for detail message ... maybe log it
    return { type: API_ERRORED, payload : {id: 1, message: 'API error!'} }
};
